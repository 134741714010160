<template>
  <div class="pos_rel bor_dashed_1_ddd bor_rad_8 over_hid">
    <!-- 上传图标 -->
    <div
      class="
        pos_abs
        l_0
        t_0
        w_100_per
        h_100_per
        dis_flex
        ju_con_cen
        ali_it_cen
        z_1
      "
    >
      <img
        class="w_48 h_48"
        src="http://songcw-dev.oss-cn-shanghai.aliyuncs.com/car-agent/20220613/PUgiAppXnBuTEftsvOmm.png"
      />
    </div>

    <!-- 图片 -->
    <div
      v-if="isOnlyImg && src"
      class="w_100_per h_100_per"
      :style="`background: url('${src}') no-repeat center/cover;`"
    />

    <!-- 文件选择 -->
    <input
      class="pos_abs l_0 t_0 opa_0 w_100_per h_100_per z_2"
      type="file"
      @change="change"
      :accept="isOnlyImg ? 'image/jpeg, image/png, image/gif' : ''"
    />

    <!--  -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import { loanIncomingPartsUploadPost } from '@/api/index';

export default {
  props: {
    // 图片链接
    src: {
      type: String,
      default: () => '',
    },

    // 是否仅图片
    isOnlyImg: {
      type: Boolean,
      default: () => false,
    },

    // 是否仅返回file
    isReturnFile: {
      type: Boolean,
      default: () => false,
    },

    //
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    // 变化
    async change(e) {
      const file = e.currentTarget.files[0];
      if(!file) return;

      if (this.$props.isReturnFile) {
        this.$emit('handleUpload', file);
        return;
      }

      let params = new FormData();
      params.append('file', file);

      try {
        Toast.loading({
          duration: 10 * 1000,
          message: '请稍后..',
          forbidClick: true,
        });

        const data = await loanIncomingPartsUploadPost(params);
        this.$emit('handleUpload', data);

        //
      } catch (err) {
        Toast(err);
      } finally {
        Toast.clear();
      }

      //
    },

    //
  },
};
</script>

<style lang="scss" scoped>
</style>
